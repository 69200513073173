import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
//components
import { AddressComponent } from './address/address.component';

//Pipes
import { FormatCentrisecondsPipe } from './pipes/format-centriseconds.pipe';
import { OrdinalSuffixPipe } from './pipes/ordinal-suffix.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { TimeUntilPipe } from './pipes/time-until.pipe';
import { ToArrayPipe } from './pipes/to-array.pipe';

//Modules
import { SidebarModule } from './sidebar/sidebar.module';
import { FooterModule } from './footer/footer.module';
import { NavbarModule } from './navbar/navbar.module';
import { FixedpluginModule } from './fixedplugin/fixedplugin.module';
import { FieldErrorDisplayComponent } from './field-error-display/field-error-display.component';
import { Int2RomanPipe } from './pipes/int-2-roman.pipe';
import { SwimWarriorBadgeComponent } from './swim-warrior-badge/swim-warrior-badge.component';
import { ReplacePhotoComponent } from './replace-photo/replace-photo.component';
import { AddPhotoComponent } from './add-photo/add-photo.component';
import { ReplaceLogoComponent } from './replace-logo/replace-logo.component';
import { MdChartComponent } from './md-chart/md-chart.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { ConfirmButtonAlertComponent } from './confirm-button-alert/confirm-button-alert.component';
import { ConfirmIconAlertComponent } from './confirm-icon-alert/confirm-icon-alert.component';
import { ImportCsvComponent } from './import-csv/import-csv.component';
import { ImportExcelComponent } from './import-excel/import-excel.component';
import { EquipmentImgComponent } from './equipment-img/equipment-img.component';
import { MaterialModule } from '../material.module';
import { ScheduleCalendarComponent } from './schedule-calendar/schedule-calendar.component';
import { DayPilotModule } from '@daypilot/daypilot-lite-angular';
import { FastestTimesComponent } from './fastest-times/fastest-times.component';
import { TimeDetailsComponent } from './time-details/time-details.component';
import { StrokeDistanceTimesComponent } from './stroke-distance-times/stroke-distance-times.component';
import { BarChartRankComponent } from './bar-chart-rank/bar-chart-rank.component';
import { BarChartAgesComponent } from './bar-chart-ages/bar-chart-ages.component';
import { BarChartRacesComponent } from './bar-chart-races/bar-chart-races.component';
import { TimeStandardsChartComponent } from './time-standards-chart/time-standards-chart.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormatSecondsPipe } from './pipes/format-seconds.pipe';
import { FeedbackComponent } from './feedback/feedback.component';
import { UploadTimesDialog } from './upload-times-dialog/upload-times.dialog.component';
import { UploadUsaTimesDialog } from './upload-usa-times-dialog/upload-usa-times.dialog.component';
import { SvgIconComponent } from './svg-icon/svg-icon.component';

@NgModule({
  declarations: [
    PhonePipe,
    ToArrayPipe,
    TimeUntilPipe,
    OrdinalSuffixPipe,
    AddressComponent,
    FormatCentrisecondsPipe,
    FormatSecondsPipe,
    FieldErrorDisplayComponent,
    Int2RomanPipe,
    SwimWarriorBadgeComponent,
    ReplacePhotoComponent,
    ReplaceLogoComponent,
    AddPhotoComponent,
    MdChartComponent,
    ConfirmButtonAlertComponent,
    ConfirmIconAlertComponent,
    ImportCsvComponent,
    ImportExcelComponent,
    EquipmentImgComponent,
    ScheduleCalendarComponent,
    FastestTimesComponent,
    TimeDetailsComponent,
    StrokeDistanceTimesComponent,
    BarChartRankComponent,
    BarChartAgesComponent,
    BarChartRacesComponent,
    TimeStandardsChartComponent,
    FeedbackComponent,
    UploadTimesDialog,
    UploadUsaTimesDialog,
    SvgIconComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    TitleCasePipe,
    BreadcrumbModule,
    MaterialModule,
    DayPilotModule,
    NgbModule,
    FormsModule,
  ],
  exports: [
    BreadcrumbModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AddressComponent,
    FieldErrorDisplayComponent,
    SidebarModule,
    NavbarModule,
    FooterModule,
    FixedpluginModule,
    MaterialModule,
    PhonePipe,
    ToArrayPipe,
    TimeUntilPipe,
    Int2RomanPipe,
    OrdinalSuffixPipe,
    TitleCasePipe,
    AddressComponent,
    FormatSecondsPipe,
    FormatCentrisecondsPipe,
    ConfirmButtonAlertComponent,
    ConfirmIconAlertComponent,
    SwimWarriorBadgeComponent,
    ReplacePhotoComponent,
    ReplaceLogoComponent,
    AddPhotoComponent,
    MdChartComponent,
    ImportCsvComponent,
    ImportExcelComponent,
    EquipmentImgComponent,
    ScheduleCalendarComponent,
    DayPilotModule,
    FastestTimesComponent,
    TimeDetailsComponent,
    StrokeDistanceTimesComponent,
    BarChartRankComponent,
    BarChartAgesComponent,
    BarChartRacesComponent,
    TimeStandardsChartComponent,
    NgbModule,
    UploadTimesDialog,
    UploadUsaTimesDialog,
    SvgIconComponent,
  ],
})
export class SharedModule {}
